
import Navbar from "../../components/Navbar";

const Support = () => {


  return (
    <div>
      <Navbar />
        <div className="flex h-screen justify-center items-center container">

            <h1 className="text-5xl">Click the button below</h1>
        </div>
      {/* <section id="contact" className="h-full md:h-screen md:grid grid-cols-4">
        <div className="col-span-1 flex items-center mx-auto my-8 md:my-0">
          
        </div>

        <div className="col-span-3 bg-black text-white flex">
            </div>
            </section> */}
    </div>
  );
};

export default Support;
