import Contact from "./pages/contact";
import Landing from "./pages/landing";
import { Privacy } from "./pages/privacy";
import Support from "./pages/support";
import Waitlist from "./pages/waitlist";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Routes, Route } = require("react-router-dom");

function App() {
  return (
    <>
       <ToastContainer />
       <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
    </>
  );
}

export default App;
